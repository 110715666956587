import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class IBaseSpartanService {
  abstract get BaseHostUri(): string;
  abstract set BaseHostUri(uri: string);
  abstract get<T>(uri: string, headers?: HttpHeaders): Observable<HttpResponse<T>>;
  abstract post<T>(uri: string, data: any, headers?: HttpHeaders): Observable<HttpResponse<T>>;
  abstract put<T>(uri: string, data: any, headers?: HttpHeaders): Observable<HttpResponse<T>>;
  abstract delete(uri: string, headers?: HttpHeaders): Observable<any>;
}
