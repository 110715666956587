import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginComponent } from './components/login/login.component';
import { LoginTenantSelectionComponent } from './components/login/login-tenant-selection.component';
import { ResetLoginComponent } from './components/reset-login/reset-login.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { CollapsibleToastComponent } from './components/collapsible-toast/collapsible-toast.component';

import { TitleService } from './services/title.service';

@NgModule({
  declarations: [
    NotFoundComponent,
    LoginComponent,
    LoginTenantSelectionComponent,
    NotAuthorizedComponent,
    ResetLoginComponent,
    CollapsibleToastComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NotFoundComponent,
    LoginComponent,
    LoginTenantSelectionComponent,
    NotAuthorizedComponent,
    ResetLoginComponent,
    CollapsibleToastComponent,
  ],
  providers: [
    TitleService
  ]
})
export class SharedModule { }
