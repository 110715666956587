import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';

import { SpartanToken } from '../models/spartan-token';

import { ApplicationInsightsService } from './applicationInsights.service';
import { AccountInfo } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
    static count = 1;
    private aadInstance = 'https://login.microsoftonline.com/';
    public static readonly msftTenant: string = '72f988bf-86f1-41af-91ab-2d7cd011db47';
    public static readonly studioDevTenant: string = '27d5a614-5e32-4ce9-9207-b3a63945b5bb';
    public static readonly clientId = 'abb09453-882a-46b8-b0b1-e9bac4fe58c0';
    public static readonly prodTenant: string = '2094aafd-3045-4703-a6f1-ad112ff1babe';
    public static readonly homeTenant: string = 'a9063893-a7c6-46ba-9e91-82f09ea18d17';
    private readonly _destroying$ = new Subject<void>();
    public loginDisplay = false;
    private _account: AccountInfo;
    public TENANTS;

    public aadTenantId: string = AuthService.homeTenant;

    myCount = AuthService.count++; // eslint-disable-line
    timeCount = 0;

    private tokenCurrentAudience = '';
    private spartanTokenHost = '';

    protected spartanToken: SpartanToken;

    constructor(
        private http: HttpClient,
        private appInsights: ApplicationInsightsService,
        private msal: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        tenants
    ) {
        this.aadTenantId = localStorage.getItem('tenant-id') || AuthService.studioDevTenant;
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$),
            )
            .subscribe(() => {
                const authResult = this.msal.instance.getActiveAccount();
                if (authResult) {
                    this.msal.instance.setActiveAccount(authResult);
                }
            });

        this.TENANTS = tenants;
    }

    get Alias(): string {
        const account = this.msal.instance.getActiveAccount();
        if (account) {
            const name: string = account?.username;
            return name.slice(0, name.indexOf('@'));
        }
        return '';
    }

    get account(): AccountInfo {
        // if user already logged in but reloaded the page, set account from msal instance
        if (!this._account) {
            this._account = this.getUser();
        }
        return this._account;
    }

    get CanonicalToken(): string {
        if (this?.account?.idTokenClaims) {
            return (AuthService.msftTenant === this.account.idTokenClaims?.tid)
                ? `auid(${this.account.idTokenClaims?.oid})`
                : `atui(${this.account.idTokenClaims?.tid}.${this.account.idTokenClaims?.oid})`;
        }
        return '';
    }

    get Id(): string {
        return `oid(${this.account?.idTokenClaims?.oid || ''})`;
    }


    /** Checks if user is logged in */
    check() {
        return !!this.msal.instance.getActiveAccount();
    }

    /** Fetches the currently logged in user. */
    getUser(): any {
        return this.msal.instance.getActiveAccount();
    }

    /** Executes the login flow. */
    login() {
        this.msal.instance.loginRedirect();
    }

    /** Executes the logout flow. */
    logOut() {
        localStorage.clear();
        this.msal.instance.logoutRedirect({
            onRedirectNavigate: (url) => {
                window.location.href = url;
                return false;
            },
        });
    }

    getCurrentTenant() {
        return this.TENANTS.filter(t => t.id === this.aadTenantId)[0];
    }

    /**
     * Updates the stored Tenant ID
     * @param newTenentId - New Tenant ID
     */
    changeTenantId(newTenentId: string, newAadInstance?: string): void {
        this.aadTenantId = newTenentId;
        this.aadInstance = newAadInstance || this.aadInstance;
        localStorage.setItem('tenant-id', this.aadTenantId);
        localStorage.setItem('nextTenant', this.aadTenantId);
    }

    /** Fetches the spartan token. */
    async getSpartanToken(
        tokenAudience: string = 'https://settings.test.svc.halowaypoint.com/spartan-token',
        spartanTokenHost: string = 'https://settings-IntOne.test.svc.halowaypoint.com',
        environment: string = 'test',
        isTestEnv: boolean = true,
    ): Promise<SpartanToken> {
        if (!this.spartanToken
            || moment().isSameOrAfter(this.spartanToken.ExpiresUtc.ISO8601Date)
            || this.tokenCurrentAudience !== tokenAudience
            || this.spartanTokenHost !== spartanTokenHost) {

            this.tokenCurrentAudience = tokenAudience;
            this.spartanTokenHost = spartanTokenHost;
            console.debug('AUTH: ST null or expired');

            try {
                const token = await this.acquireToken(tokenAudience);
                const reqData = {
                    Audience: 'urn:343:s3:services',
                    MinVersion: 4,
                    Proof: [{
                        TokenType: 'AAD_v1Token',
                        Token: token,
                    }],
                };

                console.debug('AUTH: ST RC FETCH');

                this.appInsights.setUserId(this.CanonicalToken);

                const response = await this.http.post(`${spartanTokenHost}/spartan-token`, reqData).toPromise();
                this.spartanToken = response as SpartanToken;
            } catch (e) {
                console.error(e);
                throw new Error( e?.error?.message || 'Failed to acquire Spartan Token' );  
            }
        }

        const date = new Date();
        date.setTime(date.getTime() + (10 * 60 * 1000)); // 10m cookie
        document.cookie = `x-343-st=${this.spartanToken.SpartanToken},${isTestEnv},${environment};expires=${date.toUTCString()};path=/`;
        return this.spartanToken;
    }


    /** Acquires an AAD token. */
    async acquireToken(audience: string, canonicalToken: any = null): Promise<string | null> {
        const tenantId = localStorage.getItem('tenant-id') || AuthService.studioDevTenant;

        if (!this.account) {
            await this.msal.instance.loginPopup();
        }
        const tokenRequest = {
            scopes: [
                `${audience}/user_impersonation`,
            ],
            audience: tenantId === AuthService.prodTenant
                ? 'https://settings.svc.halowaypoint.com/spartan-token' :
                canonicalToken
                    ? 'https://graph.microsoft.com/v1.0/users/*'
                    : audience,
            prompt: 'none',
            authority: tenantId === AuthService.prodTenant ? `https://login.microsoftonline.com/${tenantId}/` : ''
        };

        try {
            const { accessToken } = await this.msal.instance.acquireTokenSilent(tokenRequest);
            if (!accessToken) throw new Error('No access token');
            return accessToken;
        } catch (err) {
            if (err?.name === 'InteractionRequiredAuthError' || err?.name === '') {
                const { accessToken } = await this.msal.instance.acquireTokenPopup(tokenRequest);
                console.debug('response -> acquireTokenPopup', accessToken);
                return accessToken;
            }
            return null;
        };
    }

    setLoginDisplay() {
        this.loginDisplay = this.msal.instance.getAllAccounts().length > 0;
    }

    public setAccount(account: AccountInfo) {
        this._account = account;
    }
}