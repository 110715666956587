<!--- DO NOT CHANGE THIS FILE -->
<div class="container">
    <br /><br />
    <div class="notification is-danger">
            <button class="delete"></button>
            <h2 class="title is-2">404</h2>
            <p>We don't know what you're looking for. And at this point, we're too afraid to ask.</p>
            <hr>
            <p class="mb-0">Maybe you'd like to go back to <a routerLink="/">the homepage</a>?</p>
          </div>

<div class="alert alert-danger" role="alert">        
</div>
</div>

