import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SpartanInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = JSON.stringify(request.headers);
    const path = request.url;
    console.debug(`intercepted request ${path}, headers were: ${headers}`);
    return next.handle(request);
  }
}
