<!--- DO NOT CHANGE THIS FILE -->
<div class="container" style="text-align: center;" *ngIf="!showTenants">
    <ng-container>
      <br />
      <br />
      <img src="/assets/343.svg" height="150" width="150">
      <br />
      <br />
      Authenticating...
      
      <span *ngIf="canRetry">
        <br />
        <br />
        Taking too long?
        <a (click)="logout()">
          Click here to signout and try again.
        </a>
      </span>
    </ng-container>
  </div>
    
  <div class="container" style="max-width: 300pt;" *ngIf="showTenants">
    <login-tenant-selection></login-tenant-selection>
  </div>
    
  <div class="container" *ngIf="state.signInError">  
    <br />
    <br />
    <div class="notification is-danger">
      <button class="delete"></button>
      <h2 class="title is-2">Sign-in Failure</h2>
      <p>
        Something went wrong with your sign in. Perhaps you might want to <a (click)="logout()"> try a different tenant?</a>
      </p>
    </div>
    <br />
    <br />
    <br />
    <b>Error, stringified...</b>
    <br />
    {{ loginError | json }}
  </div>