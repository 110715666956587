import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalInterceptorConfiguration, ProtectedResourceScopes, MsalGuardConfiguration } from '@azure/msal-angular';

export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const protectedResourceMap: [string, string[]][] = [];

export const AppRegistrationClientId: string = 'abb09453-882a-46b8-b0b1-e9bac4fe58c0';
export const StudioDevTenantId: string = '27d5a614-5e32-4ce9-9207-b3a63945b5bb';

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(`LOG LEVEL: ${LogLevel[logLevel]} \n MESSAGE: ${message}`);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    const tenantId = localStorage.getItem('tenant-id') || StudioDevTenantId;
    return new PublicClientApplication({
        auth: {
            clientId: AppRegistrationClientId,
            authority: `https://login.microsoftonline.com/${tenantId}`,
            redirectUri: window ? window.location.origin : null,
            postLogoutRedirectUri: window.location.origin
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE,
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes>>();
    protectedResourceMap.set('https://settings.svc.halowaypoint.com/spartan-token', [{ httpMethod: 'POST', scopes: ['user.read'] }]);
    protectedResourceMap.set('https://settings.test.svc.halowaypoint.com/spartan-token', [{ httpMethod: 'POST', scopes: ['user.read'] }]);
    protectedResourceMap.set('https://settings-intone.test.svc.halowaypoint.com/spartan-token', [{ httpMethod: 'POST', scopes: ['user.read'] }]);
    protectedResourceMap.set('https://settings-play.test.svc.halowaypoint.com/spartan-token', [{ httpMethod: 'POST', scopes: ['user.read'] }])
    protectedResourceMap.set('https://settings-certone.test.svc.halowaypoint.com/spartan-token', [{ httpMethod: 'POST', scopes: ['user.read'] }]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
        authRequest: (_msalService, _http, originalAuthRequest) => {
            return {
                ...originalAuthRequest,
                authority: `https://login.microsoftonline.com/${originalAuthRequest.account?.tenantId ?? 'organizations'}`
            }
        }
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    const tenantId = localStorage.getItem('tenant-id') || StudioDevTenantId;
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            authority: `https://login.microsoftonline.com/${tenantId ?? StudioDevTenantId}/`,
            scopes: ['openid', 'profile', 'email', 'offline_access']
        },
        loginFailedRoute: "./login-failed"
    };
}