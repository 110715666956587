import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MsalModule, MsalService, MsalGuard, MsalBroadcastService, MsalRedirectComponent, MSAL_INTERCEPTOR_CONFIG, MsalInterceptor } from '@azure/msal-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BundleErrorHandler } from './bundleError.handler';
import { SharedModule, ApplicationInsightsService, AuthService, MSALInstanceFactory, MSALInterceptorConfigFactory, MSALGuardConfigFactory, NotificationService } from "shared";
import { TENANTS } from "./tenants.constants";
import { SpartanInterceptor } from 'projects/gamecms/src/app/auth.interceptor';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'register-client' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ToastrModule.forRoot({ preventDuplicates: true, resetTimeoutOnDuplicate: true }),
    MsalModule.forRoot(MSALInstanceFactory(), MSALGuardConfigFactory(), MSALInterceptorConfigFactory()),
    SharedModule,
  ],
  providers: [NotificationService,
    { provide: ErrorHandler, useClass: BundleErrorHandler },
    {
      provide: AuthService,
      useFactory: (
        httpClient: HttpClient,
        appInsightsService: ApplicationInsightsService,
        msal: MsalService,
        msalBroadcast: MsalBroadcastService,
      ) => new AuthService(
        httpClient,
        appInsightsService,
        msal,
        msalBroadcast,
        TENANTS),
      deps: [HttpClient, ApplicationInsightsService, MsalService, MsalBroadcastService]
    },
    {
      provide: ApplicationInsightsService,
      useFactory: (router: Router) => new ApplicationInsightsService(router, '9eb5c3f5-8434-4b4f-be76-13174b7e83e1'),
      deps: [Router]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpartanInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalBroadcastService,
    MsalService,
    MsalGuard,
    { provide: 'APP_ID', useValue: 'register-client' },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
