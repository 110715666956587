<!--- DO NOT CHANGE THIS FILE -->
<div class="container">
    <br /><br />
    <div class="notification is-danger">
        <button class="delete"></button>
        <h2 class="title is-2">403</h2>
        <p>Sorry, you are not recognized by this system or your clearance level is not sufficient.</p>
        <hr>
        <p class="mb-0">You can visit <a
                href="https://studio.343i.xboxgamestudios.com/display/CD/Access%20to%20APIs%2c%20Tools%2c%20and%20Admin%20Sites">this
                page</a> for more information & access request links. Or, maybe you'd like to go back to <a
                routerLink="/">the homepage</a>?</p>
    </div>
    <div class="alert alert-danger" role="alert">
    </div>
</div>