/// /////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE.      //////////////
/// /////////////////////////////////////////////
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { LoginComponent } from "shared";
import { NotAuthorizedComponent } from "shared";
import { NotFoundComponent } from "shared";
import { ResetLoginComponent } from "shared";

/// New routes should be added in the guarded-routing.module.
const routes: Routes = [
  { path: 'auth', title: 'Register Client Authorization', component: MsalRedirectComponent },
  { path: 'login', title: 'Register Client Login', component: LoginComponent },
  { path: 'reset-login', title: 'Reset Login', component: ResetLoginComponent },
  {
    path: 'guard',
    loadChildren: () => import('./guarded/guarded.module').then((m) => m.GuardedModule),
    canActivate: [MsalGuard],
  },
  { path: '', title: 'Register Client Login', component: LoginComponent },
  { path: '404', title: 'Page Not Found', component: NotFoundComponent },
  { path: '403', title: 'Not Authorized', component: NotAuthorizedComponent },
  { path: '**', title: 'Page Not Found', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking', useHash: false,
})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
