import { Component } from '@angular/core';

import { AuthService } from '../../services/auth.service';

@Component({
    templateUrl: './login-tenant-selection.template.html',
    selector: 'login-tenant-selection',
})
/**
 * TenantSelectionComponent - Renders a button to toggle a dropdown which allows the user to select a new tenant to work in
 */
export class LoginTenantSelectionComponent {
    readonly TENANTS = this.auth.TENANTS;

    constructor(
        private auth: AuthService,
    ) { }

    /**
     * selectTenant - Click handler for tenant dropdown. This will set the current tenant within the auth service,
     * then refresh the page to process the login in the new tenant.
     * @param index - Index within the TENANTS array of the tenant which was selected
     */
    selectTenant(index: number): void {
        this.auth.changeTenantId(this.TENANTS[index].id, this.TENANTS[index].address);
        window.location.reload();
    }
}