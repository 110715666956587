import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, NgZone } from '@angular/core';
import { IndividualConfig, Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'collapsible-toast-component',
  templateUrl: './collapsible-toast.component.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}'),
      ),
      transition(
        'active => removed',
        animate('{{ easeTime }}ms {{ easing }}'),
      ),
    ]),
  ],
})
export class CollapsibleToastComponent extends Toast {

  constructor(toastrService: ToastrService, toastPackage: ToastPackage, ngZone? : NgZone) {
    super(toastrService, toastPackage, ngZone);
  }
  public areExtraMessagesHidden: boolean = true;

  /**
     * Copies all the toast's messages to the clipboard
     */
  copyMessagesToClipboard(): void {
    let messageText = this.message as string;

    (this.options as CollapsibleConfig).extraMessages.forEach(message => {
      messageText = messageText.concat('\n', message);
    });

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = messageText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public getOptionsAsCollapsible() {
    return (this.options as CollapsibleConfig);
  }
}

export interface CollapsibleConfig extends IndividualConfig {
  extraMessages: string[];
}
