/// /////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE.      //////////////
/// /////////////////////////////////////////////

import moment from 'moment';

export interface SpartanToken {
  SpartanToken: string;
  ExpiresUtc: BondDateTime;
}

export interface BondDateTime {
  ISO8601Date: moment.Moment;
}
