import { Injectable, Optional, Inject, PLATFORM_ID } from '@angular/core'; 
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

import { IBaseSpartanService } from '../interfaces/base-spartan-service.interface';
import { IEnvironmentService } from '../interfaces/environment-service.interface';
import { IFlightConfigurationService } from '../interfaces/flight-configuration-service.interface';

import { TitleFamily, TitleFamilyInfo } from '../models/title-family-info';
import { ClearanceInformation } from '../models/clearance-information';

@Injectable()
export class TitleService {
  private _allTitleFamilies: TitleFamily[] = [];
  private _allTitleFamiliesSubject = new ReplaySubject<TitleFamily[]>(1);
  private _currentTitle: string;
  private _currentTitleSubject = new ReplaySubject<string>(1);
  private siteName: string;
  private _currentEnv: string;

  constructor(
    private baseSpartanService: IBaseSpartanService,
    private envService: IEnvironmentService,
    private flightConfigService: IFlightConfigurationService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject('APP_ID') appId: string
  ) {
    this.siteName = appId;

    const storedTitle = this.isBrowser() ? localStorage.getItem(this.getStorageKey()) : null;
    if (storedTitle) {
      this._currentTitle = storedTitle;
      this._currentTitleSubject.next(storedTitle);
    } 

    this.envService.current.subscribe((env) => {
      this._currentEnv = env;
      this.setHost(env);
    });
  }

  getAllTitleFamilies(): Observable<TitleFamily[]> {
    if (this._allTitleFamilies.length) {
      return this._allTitleFamiliesSubject.asObservable();
    }

    this.baseSpartanService.get<TitleFamily[]>('titlefamilies')
      .pipe(
        tap((resp: HttpResponse<TitleFamily[]>) => {
          this._allTitleFamilies = resp.body || [];
          this._allTitleFamiliesSubject.next(this._allTitleFamilies);

          if (
            this._allTitleFamilies.length &&
            !this._allTitleFamilies.some((tf) => tf.id === this._currentTitle)
          ) {
            this.currentTitle = this._allTitleFamilies[0].id;
          }
        }),
        shareReplay(1)
      )
      .subscribe();

    return this._allTitleFamiliesSubject.asObservable();
  }

  /**
   * Returns a list of valid title families.
   * When the current environment is production (as determined by envService.isProduction()),
   * this method filters out any title family with the ID 'hi343'.
   * Otherwise, it returns the full list.
   */
  getAllValidTitleFamilies(): Observable<TitleFamily[]> {
    return this._allTitleFamiliesSubject.asObservable().pipe(
      map((titles: TitleFamily[]) => {
        if (this._currentEnv && this.envService.isProduction(this._currentEnv)) {
          // In production, filter out the title with id 'hi343'
          return titles.filter(title => title.id !== 'hi343');
        }
        return titles;
      })
    );
  }

  get currentTitle$(): Observable<string> {
    return this._currentTitleSubject.asObservable();
  }

  get currentTitle(): string {
    return this._currentTitle;
  }

  set currentTitle(value: string) {
    if (!value) return;

    if (this._allTitleFamilies.length && !this._allTitleFamilies.some((tf) => tf.id === value)) {
      console.warn(`Title ID "${value}" is not in the known family list, ignoring.`);
      return;
    }

    this._currentTitle = value;
    if (this.isBrowser()) {
      localStorage.setItem(this.getStorageKey(), value);
    }
    this._currentTitleSubject.next(value);
  }

  getTitleFamilyInfo(titleFamilyId: string): Observable<TitleFamilyInfo> {
    const uri = `titlefamilies/${titleFamilyId}`;
    return this.baseSpartanService.get<TitleFamilyInfo>(uri).pipe(map((resp) => resp.body));
  }

  getClearanceInfoForCurrentTitle(): Observable<ClearanceInformation> {
    if (!this._currentTitle) {
      console.warn('No current title selected; cannot fetch clearance.');
      return null;
    }
    return this.flightConfigService.ActiveClearance(this._currentTitle).pipe(map((resp) => resp.body));
  }

  private getStorageKey(): string {
    // Use appId-based key for localStorage.
    return `${this.siteName}_lastTitle`;
  }

  private setHost(env: string) {
    switch (env.toLowerCase()) {
      case 'ci':
      case 'dev':
      case 'play':
        this.baseSpartanService.BaseHostUri = 'https://gamecms-play.test.svc.halowaypoint.com';
        break;
      case 'intone':
      case 'prod':
      case 'certone':
        this.baseSpartanService.BaseHostUri = 'https://gamecms.svc.halowaypoint.com';
        break;
      default:
        console.debug('Invalid environment', env);
        this.baseSpartanService.BaseHostUri = 'https://gamecms.invalid';
    }
  }

  private isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
