import { Component, OnInit, Input, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { EventType, InteractionStatus, AuthenticationResult } from '@azure/msal-browser';
import { MsalBroadcastService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ISiteConfigService } from '../../interfaces/site-config-service.interface';

@Component({
  selector: 'login',
  templateUrl: './login.template.html',
})
export class LoginComponent implements OnInit {
  @Input() public redirectPath: string;
  private readonly _destroying$ = new Subject<void>();

  public isLoggedIn: boolean;
  public showTenants: boolean = false;
  public spartanToken: string;
  public canRetry: boolean = false;
  public subscription: any;

  public loginError: any = null;

  public state: any = {
    signInError: false,
  };

  constructor(
    private auth: AuthService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    @Optional() private siteConfigService: ISiteConfigService
  ) {}

  public async ngOnInit() {
    const currentTenant = this.auth.getCurrentTenant();

    let environment = localStorage.getItem('lastEnv');
    const lastTenantId = localStorage.getItem('tenant-id');

    // Use default values if siteConfigService is not provided
    const siteName = this.siteConfigService ? this.siteConfigService.getSiteName() : '';
    const defaultTitleFamily = this.siteConfigService ? this.siteConfigService.getDefaultTitleFamily() : 'oban';

    const titleKey = `${siteName}_lastTitle`;
    const title = localStorage.getItem(titleKey) || defaultTitleFamily;

    if (!lastTenantId || lastTenantId !== currentTenant.id || !environment) {
      environment = currentTenant?.defaultEnv;
    }

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.auth.setLoginDisplay();
      });

    this.subscription = this.msalBroadcastService.msalSubject$
      .pipe(filter((msg) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe(async (data) => {
        console.debug('LOGIN: on init', data);
        const { account } = data.payload as AuthenticationResult;
        if (!environment) {
          environment = currentTenant?.defaultEnv;
        }
        this.auth.setAccount(account);
        await this.router.navigate([`/guard/${environment}/${title}/home`]);
      });

    console.debug(`login: is logged in?${this.isLoggedIn}`);

    this.isLoggedIn = this.auth.check();

    if (this.isLoggedIn) {
      console.debug('login: fetching ST');

      const stv4Host = `https://settings${currentTenant.stv4Affix}.svc.halowaypoint.com`;
      const stv4Aud =
        environment.toLocaleLowerCase() === 'prod'
          ? 'https://settings.svc.halowaypoint.com/spartan-token'
          : 'https://settings.test.svc.halowaypoint.com/spartan-token';
      const isTestEnv = !environment?.toLowerCase()?.startsWith('prod');

      try {
        const token = await this.auth.getSpartanToken(
          stv4Aud,
          stv4Host,
          environment?.toLowerCase(),
          isTestEnv
        );
        this.spartanToken = token?.SpartanToken;
      } catch (e) {
        console.error(e);
        this.state.signInError = true;
        this.loginError = e;
      }
    } else {
      const requestedTenant = localStorage.getItem('nextTenant');
      if (requestedTenant) {
        localStorage.removeItem('nextTenant');
        await this.auth.login();
      }
      this.showTenants = !localStorage.getItem('tenant-id');
    }

    setTimeout(() => {
      this.canRetry = true;
    }, 1000);
  }

  public logout(): void {
    localStorage.clear();
    window.location.replace(`${window.location.protocol}//${window.location.host}`);
  }

  public ngOnDestroy() {
    this._destroying$.next();
    this._destroying$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
