import { Component, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public isIframe: boolean = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private msalService: MsalService, private msalBroadcastService: MsalBroadcastService) {
    this.isIframe = window !== window.parent && !window.opener;
  }

  ngOnInit(): void {
    this.msalService.handleRedirectObservable();

    if (!this.isIframe) {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$),
        )
        .subscribe(() => {
          this.checkAndSetActiveAccount();
        });
    }

  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     */
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length) {
      const [account] = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(account);
    }
  }
}
