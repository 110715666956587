import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { TitleFamilyInfo, TitleFamily } from '../models/title-family-info';
import { IBaseSpartanService } from '../interfaces/base-spartan-service.interface';
import { ISpartanTokenService } from '../interfaces/spartan-token-service.interface';
import { IFlightConfigurationService } from '../interfaces/flight-configuration-service.interface';
import { IEnvironmentService } from '../interfaces/environment-service.interface';
import { ClearanceInformation } from '../models/clearance-information';
import { ISiteConfigService } from '../interfaces/site-config-service.interface';

interface ActiveClearance {
  title: string;
  activeClearance: string;
}

@Injectable()
export class TitleService {
  private clearanceInfo$: Observable<ClearanceInformation>;

  titleInfos: ActiveClearance[] = null;

  private _titleSubject: ReplaySubject<string>;
  private _titlesSubject: ReplaySubject<string[]>;
  private _clearanceId: ReplaySubject<string>;

  private allTitlesSubject: ReplaySubject<TitleFamily[]>;
  private allTitles: TitleFamily[];
  private titlesE2E: TitleFamily[];

  private _current: string;
  private _titles: string[];
  private currentEnv: string;
  private _env = localStorage.getItem('lastEnv') || 'IntOne';

  private _siteName: string;

  get current(): string {
    return this._current;
  }

  set current(value: string) {
    if (
      !this._current ||
      (this._titles && this._titles.some((title) => title === value) && this._current !== value)
    ) {
      this._current = value;
      localStorage.setItem(`${this._siteName}_lastTitle`, value);
      this._titleSubject.next(value);
    }
  }

  get current$(): Observable<string> {
    return this._titleSubject;
  }

  get titles(): Observable<string[]> {
    return this._titlesSubject;
  }

  get clearanceId(): Observable<string> {
    return this._clearanceId;
  }

  get allValidTitleFamilies(): Observable<TitleFamily[]> {
    return this.allTitlesSubject;
  }

  constructor(
    private http: HttpClient,
    private baseSpartanService: IBaseSpartanService,
    private spartanTokenService: ISpartanTokenService,
    private envService: IEnvironmentService,
    private flightingService: IFlightConfigurationService,
    @Optional() private siteConfigService: ISiteConfigService
  ) {
    this._titleSubject = new ReplaySubject<string>(1);
    this._titlesSubject = new ReplaySubject<string[]>(1);
    this._clearanceId = new ReplaySubject<string>(1);
    this.allTitlesSubject = new ReplaySubject<TitleFamily[]>(1);

    // Get site-specific configurations
    this._siteName = this.siteConfigService.getSiteName();
    this.allTitles = this.siteConfigService.getAllTitles();
    this.titlesE2E = this.siteConfigService.getTitlesE2E();

    this.allTitlesSubject.next(this.allTitles);

    // Initialize current title from localStorage
    this.current = localStorage.getItem(`${this._siteName}_lastTitle`);

    this.clearanceInfo$ = this.clearanceId.pipe(
      switchMap((clearance) => this.flightingService.GetClearanceInformation(clearance)),
      map((response) => response.body),
      shareReplay(1)
    );

    this.envService.current
      .pipe(
        tap((val: string) => {
          this.setHost(val);
          this.currentEnv = val;
        }),
        mergeMap(() => this.getTitles())
      )
      .subscribe(() => {
        this.lookupClearanceId().subscribe((clearanceId) => {
          this._clearanceId.next(clearanceId);
        });
      });

    if (this.envService.isE2E(this._env)) {
      this.allTitlesSubject.next(this.titlesE2E);
    } else {
      this.allTitlesSubject.next(this.allTitles);
    }
  }

  public getActiveClearanceInfo(): Observable<ClearanceInformation> {
    return this.clearanceInfo$;
  }

  getAllValidTitleFamilies(): Observable<TitleFamily[]> {
    if (!this.envService.isProduction(this.currentEnv)) return this.allTitlesSubject;

    return this.allTitlesSubject.pipe(
      map((titles) => titles.filter((title) => title.id !== 'hi343'))
    );
  }

  getTitles(): Observable<string[]> {
    return this.getFamilyInfo(this.siteConfigService.getDefaultTitleFamily()).pipe(
      map((familyInfo: TitleFamilyInfo) => {
        this._titles = familyInfo.titles;
        this._titlesSubject.next(this._titles);
        if (this._titles && !this._titles.some((title) => title === this._current)) {
          this.current = this._titles[0];
        }
        return familyInfo.titles;
      })
    );
  }

  getFamilyInfo(title: string): Observable<TitleFamilyInfo> {
    const uri = `titlefamilies/${title}`;
    return this.baseSpartanService.get<TitleFamilyInfo>(uri).pipe(map((resp: any) => resp.body));
  }

  lookupClearanceId(): Observable<string> {
    return this.flightingService
      .ActiveClearance(this._current)
      .pipe(map((r) => r.body.FlightConfigurationId));
  }

  change(title: string) {
    if (this.currentEnv.toLowerCase() === 'e2e') {
      if (this.titlesE2E) {
        let found = this.titlesE2E.find(
          (x) => x.displayName === title || x.id === title
        );
        if (!found) {
          [found] = this.titlesE2E.filter((fam) => fam.id === this.siteConfigService.getDefaultTitleFamily());
        }
        this.changeByFamily(found, 'e2e');
      }
    } else if (this.allTitles) {
      let found = this.allTitles.find(
        (x) => x.displayName === title || x.id === title
      );
      if (!found) {
        [found] = this.allTitles.filter((fam) => fam.id === this.siteConfigService.getDefaultTitleFamily());
      }
      this.changeByFamily(found, this.currentEnv);
    }
  }

  changeByFamily(title: TitleFamily, env: string) {
    if (!title) {
      return;
    }
    if (this._current === title.id && env === this.currentEnv) {
      return;
    }
    this.current = title.id;
  }

  private setHost(env: string) {
    switch (env.toLocaleLowerCase()) {
      case 'ci':
      case 'dev':
      case 'play':
        this.baseSpartanService.BaseHostUri =
          'https://gamecms-play.test.svc.halowaypoint.com';
        break;
      case 'intone':
      case 'prod':
      case 'certone':
      case 'shooter':
      case 'e2e':
        this.baseSpartanService.BaseHostUri =
          'https://gamecms.svc.halowaypoint.com';
        break;
      default:
        this.baseSpartanService.BaseHostUri = 'https://gamecms.invalid';
    }
  }
}
